<template>
  <div @keydown.enter.prevent="handleSave">
    <v-form ref="form" @submit.prevent="handleSave">
      <v-text-field
        v-model="description"
        :disabled="isLoadingData"
        :label="$trans('draft_name')"
        :rules="[rules.required, rules.maxChars(255)]"
        validate-on-blur
        outlined
        hide-details="auto"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ChangeDraftNameDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoadingData: false,
      id: null,
      description: null,
      type: this.$helpers.draftType.WEB,
      rules: {
        required,
        maxChars,
      },
    };
  },
  created() {
    this.id = this.getDialog.data.id;
    this.description = this.getDialog.data.description;
    this.type = this.getDialog.data.type;
  },
  methods: {
    ...mapActions({
      updateWebDraft: "webDraft/update",
      updateMobileDraft: "mobileDraft/update",
      refreshWebDrafts: "webDraft/refreshWebDrafts",
      refreshMobileDrafts: "mobileEditor/refreshMobileDrafts",
    }),
    handleSave() {
      if (!this.$refs.form.validate()) {
        errorNotification("form_is_invalid", {}, false);
        return;
      }

      this.isLoadingData = true;
      this.$emit("ctaButtonLoading", true);

      let updateMethod = "updateWebDraft";

      if (this.type === this.$helpers.draftType.MOBILE) {
        updateMethod = "updateMobileDraft";
      }

      pushEvent(updateMethod);

      this[updateMethod]({
        draft_id: this.id,
        description: this.description,
      })
        .then(() => {
          if (this.type === this.$helpers.draftType.MOBILE) {
            this.refreshMobileDrafts();
          } else {
            this.refreshWebDrafts();
          }

          successNotification("update_success");
          this.loading = false;
          this.$emit("ctaButtonLoading", false);
          this.$emit("close", { reload: true });
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("ctaButtonLoading", false);
          errorNotification("operation_failed", error);
        });
    },
  },
};
</script>
